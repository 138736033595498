/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

export default {
  heroContainer: {
    bg: `omegaLighter`,
    py: [4, 5],
    px: [4, 0]
  },
  privacyContainer: {
    p: [4, 4],
    content: {
      bg: `#fff`,
      boxShadow: 'lg',
      px: [5, 5],
      py: [4, 4]
    }
  },
  contentTwoContainer: {
    bg: `omegaDarker`,
    borderRadius: `0`,
    py: [4, 5],
    px: [4, 0]
  }
}
