import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@solid-ui-layout/Layout'
import { Container } from 'theme-ui'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import Header from '@solid-ui-blocks/Header/Block01'
import Footer from '@solid-ui-blocks/Footer/Block02'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import styles from './_styles'

const PrivacyPolicy = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout {...props}>
      <Seo title='Privacy Policy' indexed={true} />
      <Header content={content['header']} />
      <Divider space='4' />
      <Divider space='4' />
      {/* <FeaturesBlock02 content={content['404']} reverse /> */}
      <Container variant='wide'>
        <h1>Privacy Policy</h1>
      </Container>
      <Container variant='wide' sx={styles.privacyContainer}>
        <Container variant='wide' sx={styles.privacyContainer.content}>
          <div>
            <h2>Rand Medical Center</h2>
            <p>
              ("Community," "We," "Us") respects your privacy and is committed
              to protecting it through our compliance with the following Privacy
              Policy. This policy describes:
            </p>
            <ul>
              <li>
                The types of information we may collect from you or that you may
                provide when you visit our website (
                <strong>Randmedicalcenter.com</strong>; "website") or via email,
                text or other electronic messages between you and the Community.
              </li>
              <li>
                Our practices for collecting, using, maintaining, protecting and
                disclosing that information.
              </li>
              <li>
                Our policies and practices regarding your information and how we
                will treat it. If you do not agree with our policies and
                practices, your only recourse is not to use our website.
                However, you do have the option to opt-out of some tracking
                (see: "Choices About How We Use and Disclose Your Information").
              </li>
            </ul>
            <p>
              By accessing or using the website, you agree to this privacy
              policy.
            </p>
            <p>
              <strong>
                Information We Collect About You and How We Collect It
              </strong>
            </p>
            <p>
              We collect several types of information from and about users of
              our website, including information:
            </p>
            <ul>
              <li>
                That is about you but individually does not identify you, such
                as your age, gender and interests; how you came to the website
                (e.g., search engine, third-party website, media campaign or
                email); and the pages and features of the website you use.
              </li>
              <li>
                By which you may be personally identified, such as name, postal
                address, email address or telephone number ("personal
                information").
              </li>
              <li>
                About your Internet connection, the equipment you use to access
                our website, and your location.
              </li>
            </ul>
            <p>We collect this information:</p>
            <ul>
              <li>
                Automatically as you navigate through the site. Information
                collected automatically may include IP addresses and information
                collected through cookies, Web beacons and other tracking
                technologies (see: "Usage Details, IP Addresses and Cookies and
                Other Technologies").
              </li>
              <li>
                Directly from you when you provide it to us (see: "Information
                You Provide to Us").
              </li>
              <li>From third parties, for example, our business partners.</li>
            </ul>
            <p>
              <strong>
                Usage Details, IP Addresses, and Cookies and Other Technologies
              </strong>
            </p>
            <p>
              As you navigate through and interact with our website, we may
              automatically collect certain information about your equipment,
              browsing actions and patterns. The information we collect
              automatically is statistical data and does not identify any
              individual. It helps us improve our website and deliver better and
              more personalized future services by enabling us to:
            </p>
            <ul>
              <li>Estimate our audience size and usage patterns.</li>
              <li>
                Analyze and develop the Community’s sales and marketing
                strategy, determine how visitors find the Community and its
                website, the user’s interest regarding the Community’s services,
                and how to improve the Community’s website.
              </li>
              <li>
                Store information about your preferences, allowing us to
                customize our website according to your individual interests.
              </li>
              <li>Speed up your searches.</li>
              <li>Recognize you when you return to our website.</li>
            </ul>
            <p>
              We do not collect personal Information automatically, but we may
              tie this information to personal information about you that we
              collect from other sources or that you provide to us. The
              technologies we use for this automatic data collection may
              include:
            </p>
            <p>
              <strong>Cookies (or browser cookies).</strong> A cookie is a
              string of information that a website stores on a visitor’s
              computer, and that the visitor’s browser provides to the website
              each time the visitor returns. This website uses cookies to help
              the website owner and marketing partner(s) identify and track
              visitors, their usage of the owner’s website, and their website
              access preferences. Website visitors who do not wish to have
              cookies placed on their computers should set their browsers to
              refuse cookies before using this website, with the drawback that
              certain features of the website may not function properly without
              the aid of cookies. Using Google’s{' '}
              <a href='https://www.google.com/settings/u/0/ads/authenticated'>
                Ads Settings
              </a>
              , visitors can opt-out of Google Analytics for Display Advertising
              and customize Google Display Network ads.
            </p>
            <p>
              <strong>Flash Cookies.</strong> Certain features of our website
              may use local stored objects (or Flash cookies) to collect and
              store information about your preferences and navigation to, from
              and on our website. Flash cookies are not managed by the same
              browser settings used for browser cookies. For information about
              managing your privacy and security settings for Flash cookies, see
              "Choices About How We Use and Disclose Your Information."
            </p>
            <p>
              <strong>Web Beacons.</strong> Pages of our website and our e-mails
              may contain small electronic files known as web beacons (also
              referred to as clear gifs, pixel tags and single-pixel gifs) that
              permit us, for example, to count specific visitors who have
              visited certain pages or opened and clicked an e-mail. The
              information we collect from these beacons is sent to our marketing
              automation and email marketing service called Act-On (
              <a href='https://www.act-on.com/'>www.act-on.com</a>).
            </p>
            <p>
              <strong>Information You Provide to Us</strong>
            </p>
            <p>
              The information we collect on or through our website may include:
            </p>
            <ul>
              <li>
                Information that you provide by filling in forms on our website.
                This includes, but is not limited to, information provided when
                you submit a request for information or to be contacted by the
                Community; a request for materials from the Community (e.g.,
                DVDs, white papers and brochures); registering for an event; or
                subscribing to email communication.
              </li>
              <li>
                Records and copies of your correspondence (including e-mail
                addresses), if you contact us.
              </li>
              <li>
                Your responses to surveys that we might ask you to complete for
                research purposes.
              </li>
              <li>
                Details of transactions you carry out through our website and of
                the fulfillment of your orders.
              </li>
              <li>Your search queries on the Site.</li>
            </ul>
            <p>
              <strong>How We Use Your Information</strong>
            </p>
            <p>
              The Community and/or its agents use information that we collect
              about you or that you provide to us, including any personal
              information:
            </p>
            <ul>
              <li>
                To provide you with information, products or services that you
                request from us.
              </li>
              <li>To fulfill any other purpose for which you provide it.</li>
              <li>For marketing purposes.</li>
              <li>To present our website and its contents to you.</li>
              <li>
                To allow you to participate in interactive features on our
                website.
              </li>
              <li>
                In any other way we may describe when you provide the
                information.
              </li>
              <li>For any other purpose with your consent.</li>
            </ul>
            <p>We never sell your personal information.</p>
            <p>
              <strong>
                Choices About How We Use and Disclose Your Information
              </strong>
            </p>
            <p>
              We strive to provide you with choices regarding the personal
              information you provide to us. We have created mechanisms to
              provide you with the following control over your information:
            </p>
            <p>
              <strong>Tracking Technologies and Advertising.</strong> You can
              set your browser to refuse all or some browser cookies, or to
              alert you when cookies are being sent. To learn how you can manage
              your Flash cookie settings, visit the Flash player settings page
              on Adobe’s website. If you disable or refuse cookies, please note
              that some parts of this site may then be inaccessible or not
              function properly.
            </p>
            <p>
              This website uses Google Analytics to track website activity and
              visitor behavior. If you want to opt-out of being tracked by
              Google Analytics, you may use the{' '}
              <a href='https://tools.google.com/dlpage/gaoptout/'>
                Google Analytics Opt-out Browser Add-on
              </a>
              . Google Analytics also collects data about visits to the website
              via the DoubleClick cookie and Floodlight tags. This information
              is being used to provide demographic and interest data about
              visitors to the website and to target online display
              advertisements on third-party websites. Using Google’s{' '}
              <a href='https://www.google.com/settings/ads'>Ads Settings</a>,
              visitors can opt-out of Google Analytics for Display Advertising
              and customize Google Display Network ads.
            </p>
            <p>
              <strong>Promotional Offers from the Community.</strong> If you do
              not wish to have your contact information used by the Company to
              promote our own services, you can follow the link to unsubscribe
              from mailing lists at the bottom of email communication; send an
              e-mail to{' '}
              <a href='mailto:marketing@plymouthplace.com'>admin@allfhc.com</a>{' '}
              stating your request; call{' '}
              <a href='tel:+17083540340'>224-735-3522</a> and ask to be removed
              from marketing lists. This opt-out does not apply to information
              provided to the Community as a result of entering into a residence
              agreement, providing health records or other transaction.
            </p>
            <p>
              <strong>Accessing and Correcting Your Information</strong>
            </p>
            <p>
              To review and change your personal information, you may call us at{' '}
              <a href='tel:+17083540340'>224-735-3522</a> or send us an e-mail
              at patientcare@randmedical to request access to, correct or delete
              any personal information that you have provided to us. We may not
              accommodate a request to change information if we believe the
              change would violate any law or legal requirement or cause the
              information to be incorrect.
            </p>
            <p>
              <strong>Data Security</strong>
            </p>
            <p>
              We have implemented measures designed to secure your personal
              information from accidental loss and from unauthorized access,
              use, alteration and disclosure. All information you provide to us
              is stored on our secure servers behind firewalls.
            </p>
            <p>
              Unfortunately, the transmission of information via the internet is
              not completely secure. Although we do our best to protect your
              personal information, we cannot guarantee the security of your
              personal information transmitted to our website. Any transmission
              of personal information is at your own risk. We are not
              responsible for circumvention of any privacy settings or security
              measures contained on the website.
            </p>
            <p>
              <strong>Minors</strong>
            </p>
            <p>
              No portion of <strong>Rand Medical Center</strong>’s website,
              services and agency services is directed towards minors
              (individuals below the age of 18). The company does not solicit
              any information about minors, nor does it market to minors.
            </p>
            <p>
              <strong>Enforcements</strong>
            </p>
            <p>
              Questions or complaints about <strong>Rand Medical Center</strong>
              ’s privacy policy, as well as concerns about the Community’s
              enforcement of the policy, can be directed to:
            </p>
            <p>
              <strong>Rand Medical Center</strong>
              <br />
              1925 E Rand Rd.
            </p>
            <p>Arlington Heights,</p>
            <p>IL 60004</p>
            <p>
              <strong>Policy Change Notification</strong>
            </p>
            <p>We may change this policy from time to time.</p>
          </div>
        </Container>
      </Container>
      <Divider space='4' />
      <Footer content={content['footer2']} />
    </Layout>
  )
}

export const query = graphql`
  query miscRandPrivacyPolicyBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/PrivacyPolicy", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default PrivacyPolicy
